.intro-container {
    display: flex;
    flex-wrap: wrap;
}

.intro-container h2 {
    position: relative;
    margin: 2% 0;
}


.intro-wrapper p, ul, a {
    font-size: 1.2em;
    line-height: 1.2em;
    font-family: "FrightDisp";
    color: white;
}

.intro-wrapper a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: rgb(240, 240, 240);
    font-style: italic;
    align-items: center;
    justify-content: center;
}

.icon-about {
    stroke-width: 1.5px;
    animation: icon 0.6s infinite alternate ease-in-out;
  }

.intro-wrapper h3{
    font-size: 1.5em;
}

@keyframes icon {
    0% { -webkit-transform: translateY(0)}
    100% { -webkit-transform: translateY(0.3em)}
  }

@media (min-width: 668px) {
    .intro-container {
        max-width: 800px;
        margin: 0 auto;
    }
    
    .intro-container h2 {
        position: relative;
        grid-column: 1 / span 6;
    }
    
    .intro-wrapper {
        grid-column: 2 / span 4;
    }
    
    .intro-wrapper p {
        font-size: 1.5em;
        line-height: 1.2em;
        font-family: "FrightDisp";
    }

    .intro-wrapper h3{
        font-size: 3em;
    }
}
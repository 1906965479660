html {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body {
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}
html,
body {
  margin: 0;
}

.container {
  width: 8000vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

a {
  padding: 0;
}


/* .firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: yellow;
} */

.panel {
  width: 100vw;
  height: 100vh;
}
img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

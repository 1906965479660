body {
  margin: 0 auto;
  font-family: "bebas-neue-pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.separator{
  fill: rgb(20, 20, 20);
  filter: drop-shadow(0 -10px 5px #000000);
  width: 100vw;
  transform: rotateX(180deg);
}

.content-container {
  /* position: relative; */
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgb(20, 20, 20);
}

.wrapper {
  padding: 20px;
}


.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

a { 
  color: rgb(240, 240, 240);
  padding: 10px;
}

.footer a:hover, .footer a:focus {
  background-color: rgb(240, 240, 240);
  color: black;
  border-radius: 100px;
}

h2 {
  font-size: 5rem;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  color: rgb(240, 240, 240);
  bottom: 0;
  text-decoration: underline 5px;
  text-underline-offset: 8px;
  -webkit-text-decoration: underline 5px;
}

h3 {
  font-family: "FreightDisp-Pro-semibold";
  font-size: 3rem;
  line-height: 1em;
  color: rgb(240, 240, 240);
}

p {
  font-family: "FreightDisp", serif;
  color: rgb(240, 240, 240);
}

.navigation {
  top: 10px;
  position: fixed;
  z-index: 1;
}

.button {
  width: 25vw;
  padding: 1%;
  background-color: rgb(240, 240, 240);
  border-radius: 100px;
  font-family: "FreightDisp", serif;
  font-style: italic;
  color: black;
  font-weight: 400;
  font-size: 1.2em;
  text-align: center;
  margin-top: 4%;
}

.button:hover {
  background-color: black;
  color: rgb(240, 240, 240);
}

@media (min-width: 668px) {
  h2 {
    font-size: 9rem;
    line-height: 1em;
    text-transform: uppercase;
    font-weight: 600;
  }
  .button {
    width: 10vw;
  }
}

@media (min-width: 992px) {
  h2 {
    font-size: 7rem;
  }
}

@media (min-width: 1200px) {
  body {
    background-color: rgb(20, 20, 20);
  }
  .wrapper{
    margin: 0 auto;
    max-width: 1200px;
  }
}

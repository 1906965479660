.project-container {
    overflow-x: hidden;
    background-color: rgb(20, 20, 20);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
}

.project-container h2 {
    position: relative;
    width: 100vw;
}

.project-content {
    margin-top: 20%;
    display: flex;
    flex-wrap: wrap;
}

.project-img, .project-img-small {
    width: 100%;
}

.project-content-inline {
    font-size: 1.2em;
    flex: 1 1 80%;

}

.project-content a {
    display: flex;
    text-decoration: none;
    color: rgb(240, 240, 240);
    font-style: italic;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border: solid rgb(240, 240, 240) 1px;
    border-radius: 100px;
    padding: 5px;
    width: 150px;
}

.project-content a:hover {
    cursor: pointer;
}

.project-links {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.button-project {
    font-family: "FreightDisp", serif;
    font-style: italic;
    color: rgb(240, 240, 240);
    font-weight: 400;
    font-size: 1.5em;
    text-align: left;
    background-color: transparent;
    border: none;
    position: fixed;
    z-index: 10;
    top: 5%;
    left: 5%;
  }
  
  .button-project:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .project-video {
    display: block;
    width: 100%;
  }

  .icon {
    margin-right: 5px;
    stroke-width: 1.5px;
  }

  @media (min-width: 668px) { 
    .project-container {
        padding: 50px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .project-container h2{
        position: sticky;
        left: 2%;
        bottom: 0;
    }
    .project-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 2%;
    }
    .project-img, .project-img-small {
        width: 60%;
    }
    .project-content-inline {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .project-content-inline img{
        display: flex;
        flex-direction: row;
    }
    .project-content p {
        text-align: center;
        flex: 0 0 50%;
    }
    .project-video {
        flex: 0 0 100%;
        margin: 5%;
      }

  }
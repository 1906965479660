.contact-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
}

.contact-container h2 {
    position: relative;
    margin: 2% 0;
}

.contact-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5%;
}

/* .contact-element {
    border: solid rgb(240, 240, 240) 0.5px;
    position: relative;
    transition: background-color 0.3s;
} */

.contact-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: span 2;
    gap: 10px;
    width: 100%;
  }

.contact-element {
    border: 1px solid rgb(240, 240, 240);
    transition: background-color 0.3s;
    position: relative;
    padding: 10px; /* Add padding to the boxes */
    height: 10vh;
}

.contact-element:hover, .contact-element:focus {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

.contact-element p {
    text-decoration: none;
    font-size: 1em;
    color: rgb(240, 240, 240);
    position: absolute;
    top: 0;
    margin: 10px;
    transition: color 0.3s;
    cursor: pointer;
}

.contact-element:hover p {
    color: black;
    cursor: pointer;
    pointer-events: auto;

}

.contact-element span {
    text-decoration: none;
    font-family: "FreightDisp", serif;
    font-size: 1em;
    font-style: italic;
    font-weight: 100;
    color: rgb(240, 240, 240);
    position: absolute;
    bottom: 0;
    margin: 10px;
    transition: color 0.3s;
    cursor: pointer;
}

.contact-element:hover span {
    color: black;
    cursor: pointer;
    pointer-events: auto;
}

.contact-form {
    width: 90vw;
    margin-top: 3%;
}

form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 80vw;
    color:black;
    margin: 3%;
}

.contact-label {
    margin: 3% 0 1% 0;
    font-size: 1em;
}

.contact-input {
    border-bottom: solid rgb(240, 240, 240) 1px;
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: transparent;
    font-family: "FrightDisp", serif;
    font-size: 1.2em;
    margin-top: 10%;
}

*:focus {
    outline: none;
}

.success {
    font-size: 2em;
    margin-top: 20px;
}

.error {
    font-size: 1em;
    margin-top: 10px;
    color: red;
}

@media (min-width: 668px) {
    .contact-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .contact-boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1vw;
      }

    .contact-element {
        border: 1px solid rgb(240, 240, 240);
        transition: background-color 0.3s;
        position: relative;
        padding: 10px; /* Add padding to the boxes */
        height: 15vh;
        width: 20vw;
    }
    .contact-element span {
        font-size: 100%;
    }
    .contact-form {
        width: 100%;
        margin-top: 3%;
    } 
    form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        font-family: "FreightDisp", serif;
        color:rgb(240, 240, 240);
    }
    .contact-label {
        font-size: 1.5em;
    }
}

@media (min-width: 992px) {
    form {
        max-width: 800px;
        margin-top: 0;
    } 
    .contact-boxes {
        display: flex;
        flex-wrap: nowrap;
        max-width: 900px;
      }
}
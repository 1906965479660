.navbar {
    position: relative;
}
.nav-wrapper {
    display: none;
}

.scrollToTopHidden {
    display: none;
}

.scrollToTop {
    display: block;
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 2;
    width: 40px;
    background-color: transparent;
    border: solid rgb(240, 240, 240) 3px;
    color: rgb(240, 240, 240);
    border-radius: 50%;
}

.scrollToTop:hover {
    background-color: rgb(240, 240, 240);
    color: #141414;
}

@media (min-width: 1000px) {
    .navbar {
        position: relative;
    }
    .nav-wrapper {
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 10;
        left: 0;
        top: 20%;
        border-left: 1px solid rgb(240, 240, 240);
    }
    
    .link {
        font-family: 'FreightDisp', serif;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0.2rem;
        text-decoration: none;
        color: rgb(240, 240, 240);
        margin: 50px 20px;
        opacity: 0.3;
    }

    .link:hover, .link:focus {
        opacity: 1; /* Full opacity on hover */
    }

    .active {
        opacity: 1;
    }
}